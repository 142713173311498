import React from 'react';
import moment from "moment";
//import env from '../../config';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DailyReportsTable from './daily-reports-table';
import ReportsTable from './reports-table';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
// import GetAppIcon from '@material-ui/icons/GetApp';
import { ReactComponent as MultipleDownload } from '../../public/images/multiple-download.svg';
import { TextField } from '@material-ui/core';
import { isValid } from 'date-fns';

class ReportTab extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            currMonth: moment(new Date()).format("MMM-YYYY"),
            todaysDate: moment(new Date()).format("DD-MMM-YYYY"),
            monthYr: ``,
            getDate: moment(new Date()).format("dd-MMM-yyy"),
            tabkey: props["tabkey"],
            dataapi: props["dataapi"],
            data: [],
            daily: [],
            historical: [],
            monthData: [],
            histDateOpen: false,
            next: ``,
            current: ``,
            prev: ``,
            updateFavMarked: new Date(),
            checkAll: false,
            selectCheckbox: false,
            multipleDownload: [],
            totlaUrlCounts: 0,
            updateDate: ``,
            favCount: 0,
            searchText: '',
	    errorMessage: null,
            isValid:false
        }
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleAllChecked = this.handleAllChecked.bind(this);
        this.getData = this.getData.bind(this);
        this.getHistData = this.getHistData.bind(this);
        this.favfunction = this.favfunction.bind(this);
        this.singleRowCheck = this.singleRowCheck.bind(this);
        this.handleDownloadAll = this.handleDownloadAll.bind(this);
        this.checkedITemUrl = this.checkedITemUrl.bind(this);
    }

    componentDidMount() {
        try {
            if (this.state.dataapi && this.state.dataapi !== "") {
                this.loadData(this.state.dataapi);
            }
            this.setState({ favCount: localStorage.getItem('ifscFav') ?
             JSON.parse(localStorage.getItem('ifscFav')).length : this.state.favCount });
        } catch (e) {
            console.log(e);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.dataapi !== prevProps.dataapi) {
            this.setState({ dataapi: this.props.dataapi, tabkey: this.props.tabkey }, () => {
                this.loadData(this.state.dataapi);
            })
        }
    }

    loadData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                // if(data.data.length>0)
                // {

                //     var countt=0;
                //     countt= data.data[0]["Next Day"][0].Session1.length +data.data[0]["Next Day"][0].Session2.length ;
                // this.setState({totlaUrlCounts:countt});
                // }

                if (this.state.tabkey === "monthly") {
                    this.setState({ data: data.data, monthYr: data.month });
                    if (data.data.length > 0) {
                        let countt = 0;
                        countt = data.data.length;
                        this.setState({ totlaUrlCounts: countt });
                    }
                    // console.log("monthly items", countt)
                }
                else if (this.state.tabkey === "daily") {
                    this.setState({
                        daily: data.data,
                        next: data.timestamps[0].nextDay,
                        current: data.timestamps[0].currentDay,
                        prev: data.timestamps[0].prevDay,
                        updateDate: data.timestamps[0].maxtime
                    });


                    if (data.data.length > 0) {

                        let countt = 0;
                        countt = data.data[0]["Next Day"][0].Session1.length + data.data[0]["Next Day"][0].Session2.length +
                            data.data[0]["Current Day"][0].Session1.length + data.data[0]["Current Day"][0].Session2.length +
                            data.data[0]["Previous Day"][0].Session1.length + data.data[0]["Previous Day"][0].Session2.length;
                        this.setState({ totlaUrlCounts: countt });
                    }
                }
                else if (this.state.tabkey === "historical") {
                    this.setState({ historical: data.data });
                    if (data.data.length > 0) {
                        let countt = 0;
                        countt = data.data[0].Session1.length + data.data[0].Session2.length;
                        this.setState({ totlaUrlCounts: countt });
                    }
                    // console.log("historical data", data.data)
                }
            })
            .catch((e) => { console.log("error in loading reports", e) })
    }
    handleMonthChange = (date) => {
        this.setState({
            currMonth: date,
        });
    }

    handleDateChange = (date) => {
         if(date === null || date === '' || isNaN(date.getTime())){
            this.setState({
                todaysDate: null,
                histDateOpen: false,
                errorMessage:'Please select a valid date',
                isValid:false
            });
        }else{
            this.setState({todaysDate:date, histDateOpen:false, errorMessage:'', isValid:true});
        }
    }
    getData() {
        this.setState({ currMonth: moment(this.state.currMonth).format("MMM-YYYY") },
            () => {
                this.loadMonthData(this.state.dataapi + `?forMonth=` + this.state.currMonth);
            })
    }
    getHistData() {
        this.setState({ getDate: moment(this.state.todaysDate).format("DD-MMM-YYYY") },
            () => {
                this.loadData(`/api/historical-reports?date=` + this.state.getDate);
            })
    }
    loadMonthData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({ monthData: data.data, })
            })
            .catch((e) => { console.log("error in loading monthly reports", e) })
    }

    favfunction(a) {
        this.setState({ updateFavMarked: new Date() });
        this.setState({ favCount: localStorage.getItem('ifscFav') ? 
        JSON.parse(localStorage.getItem('ifscFav')).length : this.state.favCount });
    }
    // multiple download
    handleAllChecked = (event) => {
        this.setState({ multipleDownload: [] });
        this.setState({
            selectCheckbox: event.target.checked,
            checkAll: event.target.checked,
        })

        if (!event.target.checked) {
            this.setState({ multipleDownload: [] });
        }

    };

    // callback from child to parent select functionality
    singleRowCheck(val) {

        if (!val) {
            this.setState({
                checkAll: val,
                selectCheckbox: 'DoNot'
            }
            )
        } else {

            if (this.state.totlaUrlCounts === this.state.multipleDownload.length) {
                this.setState({ checkAll: true });
            }
        }

    }

    checkedITemUrl(url, toAdd) //To Get URL
    {
        if (toAdd === true) {
            var tetamparry = this.state.multipleDownload;
            var isadded = false;
            for (let i = 0; i < tetamparry.length; i++) {
                if (tetamparry[i] === url) {
                    isadded = true;
                    break;
                }
            }
            if (!isadded) {
                tetamparry.push(url);
            }
            this.setState({ multipleDownload: tetamparry });
        }
        else {
            var tempArry = this.state.multipleDownload;
            for (let i = 0; i < tempArry.length; i++) {
                if (tempArry[i] === url) {
                    tempArry.splice(i, 1);
                    break;
                }
            }
            this.setState({ multipleDownload: tempArry });
        }
    }

    // convert to zip
    handleDownloadAll() {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = 'Multiple_Reports.zip';
        var len = this.state.multipleDownload.length;
        this.state.multipleDownload.forEach(function (url, index) {
            var urlsplt = url.split("/");
            var filename = urlsplt[urlsplt.length - 1];  //'file-' + index;
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(url, async function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count === len) {
                    var zipFile = await zip.generateAsync({ type: 'blob' });
                    saveAs(zipFile, zipFilename);
                }
            });
        });
    }

    render() {
        // console.log("passed ", this.state.selectCheckbox)
        return <>
            {this.state.tabkey === "daily" ?
                <>
                    <div className="row m-0  align-items-center mt-2">
                        <div className="col-lg-4">
                            <div className="customize-input-group align-items-center" >

                                <input type="text" className="input-form-control" placeholder="Search By Keywords"
                                    onChange={(e) => {
                                        this.setState({ searchText: e.target.value });
                                        /**
                                         * 
                                         * var countt = 0;
                                                                countt = data.data[0]["Next Day"][0].Session1.length + data.data[0]["Next Day"][0].Session2.length +
                                                                    data.data[0]["Current Day"][0].Session1.length + data.data[0]["Current Day"][0].Session2.length +
                                                                    data.data[0]["Previous Day"][0].Session1.length + data.data[0]["Previous Day"][0].Session2.length;
                                                                this.setState({ totlaUrlCounts: countt });
                                         */

                                        let count = 0;// this.state.totlaUrlCounts;
                                        let tempData = this.state.daily;
                                        if (tempData.length > 0) {
                                            var taaray = tempData[0]["Next Day"][0].Session1;
                                            for (let i = 0; i < taaray.length; i++) {
                                                if (taaray[i].file.toLowerCase().includes(e.target.value.toLowerCase())) {
                                                    count = count + 1;
                                                }
                                            }
                                            taaray = tempData[0]["Next Day"][0].Session2;
                                            for (let i = 0; i < taaray.length; i++) {
                                                if (taaray[i].file.toLowerCase().includes(e.target.value.toLowerCase())) {
                                                    count = count + 1;
                                                }
                                            }
                                            taaray = tempData[0]["Current Day"][0].Session1;
                                            for (let i = 0; i < taaray.length; i++) {
                                                if (taaray[i].file.toLowerCase().includes(e.target.value.toLowerCase())) {
                                                    count = count + 1;
                                                }
                                            }
                                            taaray = tempData[0]["Current Day"][0].Session2;
                                            for (let i = 0; i < taaray.length; i++) {
                                                if (taaray[i].file.toLowerCase().includes(e.target.value.toLowerCase())) {
                                                    count = count + 1;
                                                }
                                            }
                                            taaray = tempData[0]["Previous Day"][0].Session1;
                                            for (let i = 0; i < taaray.length; i++) {
                                                if (taaray[i].file.toLowerCase().includes(e.target.value.toLowerCase())) {
                                                    count = count + 1;
                                                }
                                            }
                                            taaray = tempData[0]["Previous Day"][0].Session2;
                                            for (let i = 0; i < taaray.length; i++) {
                                                if (taaray[i].file.toLowerCase().includes(e.target.value.toLowerCase())) {
                                                    count = count + 1;
                                                }
                                            }
                                            this.setState({ totlaUrlCounts: count })
                                            if (this.state.multipleDownload !== count) {
                                                this.setState({
                                                    checkAll: false,
                                                    selectCheckbox: 'DoNot'
                                                }
                                                )
                                            }

                                        }
                                    }}
                                />
                                <div className="input-group-append">
                                    <SearchIcon />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8" >
                            <div className="reports-features d-flex flex-wrap justify-content-end align-items-center">
                                <div className="favorites d-flex  align-items-center">
                                    <div className="headerfillcolor-star mr-1">
                                        <StarIcon className="favorite-icon" />
                                    </div>
                                    <a className="fav-link" href="/markets/reports/favorites"> Favorites</a> </div>
                                <div className="circle-icon-border">
                                    {this.state.favCount}
                                </div>
                                <FormControlLabel className="mb-0"
                                    control={<Checkbox className="selectCheckbox"
                                        onClick={this.handleAllChecked}
                                        checked={this.state.checkAll}
                                    />}
                                    label={"Select All"
                                        // + this.state.multipleDownload.length
                                    }
                                />
                                <button className="btn btn-orange  pl-4 appIconPos" onClick={() => {
                                    // console.log('Files', this.state.multipleDownload);
                                    this.handleDownloadAll();
                                }} >Multiple file download<MultipleDownload className="ml-1 multi-download-icon"/></button>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 mt-4">
                        <div className="col-lg-6">
                            <div className="d-flex align-items-center">
                                <h3 className="reports-heading">Reports for {this.state.next}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-right mb-0">Last Updated : {this.state.updateDate} IST </p>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-lg-6 mt-2">
                            {/* <div className="grid-table mt-0"> */}
                                {this.state.daily.map(it => {

                                    return it["Next Day"].map(ite => {
                                        return <DailyReportsTable heading="Session 1" key="nextSession1"
                                            selectAll={this.state.selectCheckbox}
                                            callback={this.singleRowCheck}
                                            tab={this.state.tabkey} data={ite.Session1}
                                            favMethod={this.favfunction}
                                            updateTable={this.state.updateFavMarked}
                                            addToDownload={this.checkedITemUrl}
                                            searchKey={this.state.searchText}
                                        />
                                    })
                                })}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-6 mt-2">
                            {/* <div className="grid-table mt-0"> */}
                                {this.state.daily.map(it => {
                                    return it["Next Day"].map(ite => {
                                        return <DailyReportsTable heading="Session 2" key="nextSession2"
                                            tab={this.state.tabkey} data={ite.Session2}
                                            favMethod={this.favfunction}
                                            updateTable={this.state.updateFavMarked}
                                            addToDownload={this.checkedITemUrl}
                                            selectAll={this.state.selectCheckbox}
                                            callback={this.singleRowCheck}
                                            searchKey={this.state.searchText}
                                        />
                                    })
                                })}
                            </div>
                        {/* </div> */}
                    </div>
                    <div className="col-lg-12 mt-3">
                        <h3 className="reports-heading">Reports for {this.state.current}</h3>
                    </div>
                    <div className="row m-0">
                        <div className="col-lg-6 mt-2">
                            {/* <div className="grid-table mt-0"> */}
                                {this.state.daily.map(it => {
                                    return it["Current Day"].map(ite => {
                                        return <DailyReportsTable heading="Session 1" tab={this.state.tabkey} data={ite.Session1}
                                            favMethod={this.favfunction} key="currSession1"
                                            updateTable={this.state.updateFavMarked}
                                            addToDownload={this.checkedITemUrl}
                                            selectAll={this.state.selectCheckbox}
                                            callback={this.singleRowCheck}
                                            searchKey={this.state.searchText}
                                        />
                                    })
                                })}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-6 mt-2">
                            {/* <div className="grid-table mt-0"> */}
                                {this.state.daily.map(it => {
                                    return it["Current Day"].map(ite => {
                                        return <DailyReportsTable heading="Session 2" tab={this.state.tabkey} data={ite.Session2}
                                            favMethod={this.favfunction} key="currSession2"
                                            updateTable={this.state.updateFavMarked}
                                            addToDownload={this.checkedITemUrl}
                                            selectAll={this.state.selectCheckbox}
                                            callback={this.singleRowCheck}
                                            searchKey={this.state.searchText}
                                        />
                                    })
                                })}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <h3 className="reports-heading">Reports for {this.state.prev}</h3>
                    </div>
                    <div className="row m-0">
                        <div className="col-lg-6 mt-2">
                            {/* <div className="grid-table mt-0"> */}
                                {this.state.daily.map(it => {
                                    return it["Previous Day"].map(ite => {
                                        return <DailyReportsTable heading="Session 1" tab={this.state.tabkey} data={ite.Session1}
                                            favMethod={this.favfunction} key="prevSession1"
                                            updateTable={this.state.updateFavMarked}
                                            addToDownload={this.checkedITemUrl}
                                            selectAll={this.state.selectCheckbox}
                                            callback={this.singleRowCheck}
                                            searchKey={this.state.searchText}
                                        />
                                    })
                                })}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-6 mt-2">
                            {/* <div className="grid-table mt-0"> */}
                                {this.state.daily.map(it => {
                                    return it["Previous Day"].map(ite => {
                                        return <DailyReportsTable heading="Session 2" tab={this.state.tabkey} data={ite.Session2}
                                            favMethod={this.favfunction} key="prevSession2"
                                            updateTable={this.state.updateFavMarked}
                                            addToDownload={this.checkedITemUrl}
                                            selectAll={this.state.selectCheckbox}
                                            callback={this.singleRowCheck}
                                            searchKey={this.state.searchText}
                                        />
                                    })
                                })}
                            {/* </div> */}
                        </div>
                    </div>

                </>
                : this.state.tabkey === "monthly" ?
                    <>
                        <div className="col-lg-12 mt-3">
                            <div className="d-flex justify align-items-center">
                                <FormControlLabel
                                    control={<Checkbox className="selectCheckbox"
                                        onClick={this.handleAllChecked}
                                        checked={this.state.checkAll}
                                    />}
                                    label={"Select All"
                                        // + this.state.multipleDownload.length
                                    }
                                />
                                <button className="btn btn-orange  pl-4 appIconPos" onClick={() => {
                                    // console.log('Files', this.state.multipleDownload);
                                    this.handleDownloadAll();
                                }} >Multiple file download<MultipleDownload className="ml-1 multi-download-icon"/></button>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-lg-6 rpt-tbl-align">
                                        <h3 className="reports-heading">Reports for {this.state.monthYr}</h3>
                                    </div></div>
                                {/* <div className="grid-table mt-2"> */}
                                    <ReportsTable tab={this.state.tabkey} data={this.state.data}
                                        addToDownload={this.checkedITemUrl}
                                        selectAll={this.state.selectCheckbox}
                                        callback={this.singleRowCheck} />
                                {/* </div> */}
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3 className="reports-heading">Historical Monthly Files</h3>
                                    </div></div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="customize-input-group">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    variant="inline" format={"MMM-yyyy"} views={["year", "month"]}
                                                    margin="normal" id="toDate" maxDate={new Date()}
                                                    value={this.state.currMonth} onChange={this.handleMonthChange} />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <button className="btn btn-orange pr-4 pl-4" onClick={this.getData}>Get Data</button>
                                    </div>
                                </div>
                                {/* <div className="grid-table mt-3"> */}
                                    <ReportsTable tab={this.state.tabkey} data={this.state.monthData}
                                        addToDownload={this.checkedITemUrl}
                                        selectAll={this.state.selectCheckbox}
                                        callback={this.singleRowCheck} />
                                {/* </div> */}
                            </div>
                        </div>
                    </>

                    :
                    <>
                        <div className="row m-0 hist-reports-tab">
                            <div className="col-lg-6 mt-4" >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="customize-input-group">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant="inline" format={"dd-MMM-yyyy"} views={["year", "month","date"]} openTo="year"
                                                    margin="normal" id="histDate" maxDate={new Date()}
                                                    value={this.state.todaysDate} onChange={this.handleDateChange}
                                                    autoOk={true}
                                                   renderInput ={(props) => (
                                                        <TextField {...props}
                                                        error={!this.state.isValid}
                                                        helperText={this.state.errorMessage}
                                                        onKeyDown={(e) => {
                                                            if(e.key === 'Backspace' || e.key === 'Delete' ){
                                                                this.handleDateChange(null);
                                                            }
                                                        }}
                                                        />
                                                    )} 
                                                    // KeyboardButtonProps={{
                                                    //     onFocus: (e) => {
                                                    //         this.setState({
                                                    //             histDateOpen: true
                                                    //         });
                                                    //     }
                                                    // }}
                                                    // PopoverProps={{
                                                    //     disableRestoreFocus: true,
                                                    //     onClose: () => {
                                                    //         this.setState({
                                                    //             histDateOpen: false
                                                    //         });
                                                    //     }
                                                    // }}
                                                    // InputProps={{
                                                    //     onFocus: () => {
                                                    //         this.setState({
                                                    //             histDateOpen: true
                                                    //         });
                                                    //     }
                                                    // }}
                                                    // open={this.state.histDateOpen} 
                                                    />
                                            </MuiPickersUtilsProvider>
                                        </div>
		    			 {
                                            isValid && (
                                                <span style={{color: 'red', fontSize:'14px'}}>{this.state.errorMessage}
                                            </span>
                                            )
                                         }
                                    </div>
                                    <div className="col-lg-3">
                                        <button className="btn btn-orange pr-4 pl-4 getdata" onClick={this.getHistData} disabled={ !this.state.todaysDate}>Get Data</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="d-flex justify-content-end align-items-center">
                                    <FormControlLabel
                                        control={<Checkbox className="selectCheckbox"
                                            onClick={this.handleAllChecked}
                                            checked={this.state.checkAll}
                                        />}
                                        label={"Select All"
                                            // + this.state.multipleDownload.length
                                        }
                                    />
                                    <button className="btn btn-orange pl-4 appIconPos" onClick={() => {
                                        // console.log('Files', this.state.multipleDownload);
                                        this.handleDownloadAll();
                                    }} >Multiple file download<MultipleDownload className="ml-1 multi-download-icon"/></button>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">

                            <div className="col-lg-6">

                                {/* <div className="grid-table mt-3"> */}
                                    {this.state.historical.map(it => {
                                        return <ReportsTable heading="Session 1" tab={this.state.tabkey} data={it.Session1}
                                            addToDownload={this.checkedITemUrl}  key="histSession1"
                                            selectAll={this.state.selectCheckbox}
                                            callback={this.singleRowCheck} />
                                    })}
                                {/* </div> */}
                            </div>
                            <div className="col-lg-6">
                                {/* <div className="grid-table mt-0"> */}
                                    {this.state.historical.map(it => {
                                        return <ReportsTable heading="Session 2" tab={this.state.tabkey} data={it.Session2}
                                            addToDownload={this.checkedITemUrl} key="histSession2"
                                            selectAll={this.state.selectCheckbox}
                                            callback={this.singleRowCheck} />
                                    })}
                                {/* </div> */}
                            </div>
                        </div>
                    </>}</>
    }
}

export default ReportTab;
