import React from "react";
import NSELogo from '../public/images/NSE_Clearing.jpg';
import '../App.css';

function NSEClearingPage() {

    return (
        <div className="row">
            <div className="col-md-4 my-3">
                <div className="static-image-card">

                    <div className='custom-image'>
                        <img src={NSELogo} alt="NSEICC" width="441" height="238" className="nse-img" />

                        <figcaption className="overlay flex-column">
                            <h5 className="text-start w-100"></h5>
                            <p className="text-start w-100"></p>
                            <div className="mt-2 mb-2 line-comm">
                                <p>NSE Clearing, The Organisation NSE Clearing Ltd, NSE Clearing,
                                    a wholly owned subsidiary of NSE is responsible for clearing..
                                </p>
                            </div>
                            <div className="row w-100 align-items-center">
                                <div className="col-md-7 col-7 ps-0"></div>
                                <div className="col-md-5 col-5 p-0">
                                    <a target="_blank" href="https://www.nseclearing.com" className="nse-btn d-flex align-items-center">know more</a>
                                </div>
                            </div>

                        </figcaption>

                        <div />

                    </div>

                </div>
            </div>
            <div className="col-md-12">
                <div className="custom-text">
                    <p> NSE Clearing, The Organisation NSE Clearing Ltd, NSE Clearing, a wholly owned subsidiary of NSE is responsible for clearing and settlement of all trades executed on NSE and deposit and collateral management and risk management functions. NSE Clearing was the first clearing corporation to be established in India and we introduced settlement guarantee before it became a regulatory requirement. NSE Clearing has maintained a credit rating of "AAA" from CRISIL since 2008.
                    </p>
                    <ul style={{ listStyle: 'circle' }}>It was set up with the following objectives:

                        <li>to bring and sustain confidence in clearing and settlement of securities;</li>
                        <li>to promote and maintain, short and consistent settlement cycles;</li>
                        <li>to provide counter-party risk guarantee, and</li>
                        <li>to operate a tight risk containment system.</li></ul>
                    <p>NSE Clearing commenced clearing operations in April 1996.</p>

                </div>
            </div>
        </div>
    )




}

export default NSEClearingPage;
