import React from 'react';
import moment from "moment";

class SettlementStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api: ``,
      cardData: [],
      baseApi: `/api/settlement-statistics`,
      originalData: []
    };
  }

  componentDidMount() {
    try {
      this._isMounted = true;
      this.setState({
        api: `${this.state.baseApi}`
      }, () => {
        this.loadsettlementData(this.state.api);
      })
    } catch (e) {
      console.log(e);
    }
  }

  loadsettlementData(api) {
    fetch(api)
      .then(resp => resp.json())
      .then((data) => {
        const settlementData = [];
        settlementData.push(data)
        this.setState({ originalData: settlementData });
      })
      .catch((e) => { console.log("error in loading circulars data", e) })
  }


  render() {
    return (
      <div className="container-fluid">
        <div class="container">
          <div class="table-responsive">
            <table style={{ borderSpacing: "0px 2px" }} className='MuiTable-root table customize-table'>
              <thead style={{ backgroundColor: "#0e0353" }}>
                <tr>
                  <th colspan="3" style={{ textAlign: "center", color: "rgb(255 255 255)" }}>NSEICC Settlement Statistics</th>
                </tr>
                <tr>
                  <th rowspan="2" style={{ textAlign: "center", color: "rgb(255 255 255)" }}>Trade Date</th>
                  <th colspan="2" style={{ textAlign: "center", color: "rgb(255 255 255)" }}>Settlement Value (in USD)</th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center", color: "rgb(255 255 255)" }}>Funds</th>
                  <th style={{ textAlign: "center", color: "rgb(255 255 255)" }}>Securities</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>

                {this.state.originalData[0]?.data.map((val) => {
                  return (
                    <><tr>
                      <td>{(val.TRD_DATE1) ? (moment(val.TRD_DATE1).format("DD-MM-YY")) : "-"}</td>
                      <td>{val.TOT_AMT == 0 ? "-" : val.TOT_AMT.toFixed(2)}</td>
                      <td>{val.TOT_QTY == 0 ? "-" : val.TOT_QTY}</td>

                    </tr>
                    </>
                  )
                })}


              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}
export default SettlementStatistics;
