import React,{ useEffect, useState} from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function HighChartHome(){

    const apiUrl = '/api/deep-intraday-graph';
    const apiUrl1 = '/api/niccl-graph-headers';
    const [xAxis, setxAxis] = useState([]);
    const [thresholdValue, setThresholdValue] = useState([]);
    const [numberData, setNumberData] = useState([]);

    useEffect(() => {
       const intervalId = setInterval(() => {
           fetch_graph_data_home();
           fetch_graph_number_data();
       }, 20000);
        
        fetch_graph_data_home();
        fetch_graph_number_data();

       return () => clearInterval(intervalId);
    }, []);


    const fetch_graph_data_home = async () => {
        try {
            const result = await fetch(apiUrl);
            const resp = await result.json();
            setxAxis(resp.data);
            setThresholdValue(resp.data[0].zones[0].value);
        } catch (error) {
            console.log(error);
        }
    }


    const fetch_graph_number_data = async () => {
        try {
            const result = await fetch(apiUrl1);
            const resp = await result.json();
            setNumberData(resp.data);  
        } catch (error) {
            console.log(error);
        }
    }


   const options= {
        chart: {
            type: "area",
        },
        title: {
            //     text: "Intra Day Price – Near month GIFT NIFTY Future"
        },
        rangeSelector: {
            enabled: false,
        },
        plotOptions: {
            series: {
                enableMouseTracking: true,
                color: 'green',
                negativeColor: 'red',
                threshold: null
            }

        },
        yAxis: {
            title: '',
            gridLineWidth: 1,
            minorTickInterval: 0.25,
            lineColor: 'orange',
            lineWidth: 2,
            opposite: false,
            plotLines: [{
                color: 'blue',
                width: 2,
                value: thresholdValue,
                dashStyle: 'ShortDash',
            }],
        },
        scrollbar: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            scaleX: 5,
            gridLineWidth: 1,
            minorTickInterval: 0.25,
            lineColor: 'orange',
            lineWidth: 2,
        },
        series: xAxis,
        navigator: {
            enabled: false,
        },
        legend: {
            shadow: false
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: true
        },
        navigation: {
            buttonOptions: {
                enabled: true
            }
        },
    }
    return(
<div className='col-md-6 niccl-highchart-container' >
<div className='row'>
    <div className='col-md-8 text-center' style={{ fontFamily: 'Roboto-Bold', fontStyle: 'italic', marginTop: '20px' }}>
        Intra Day Price – Near month GIFT NIFTY Future
    </div>
    <div className='col-md-4'>
        <table className='table text-center'>
            <tbody>
                <tr>
                    {numberData.map((value)=>{
                        return(
                            <>
                            <td className='table-td' style={{ fontSize: '18px', fontWeight: 'bold' }}>{value.LASTPRICE} <span>{value.PERCHANGE < 0 ? <ArrowDropDownIcon className="rate-down" /> : <ArrowDropUpIcon className="rate-up" />}</span></td>
                            <td className='table-td'>Open</td>
                            <td className='table-td'>High</td>
                            <td className='table-td'>Low</td>
                            </>
                        )
                    })}
                  
                </tr>
                <tr>
                    {numberData.map((data)=>{
                   return(
                    <>
                    <td className={'table-td-values '+ (data.PERCHANGE > 0 ? "newsupdate-font-green" : "newsupdate-font-red")} >{data.DAYCHANGE} ({data.PERCHANGE}%)<span></span></td>
                    <td className='table-td-values'>{data.DAYOPEN}</td>
                    <td className='table-td-values' style={{ color: '#0A7203' }}>{data.DAYHIGH}</td>
                    <td className='table-td-values' style={{ color: '#BF181D' }}>{data.DAYLOW}</td>
                    </>
                )
                })}
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div className='row'>
    <HighchartsReact points={2}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options} />
</div>
</div>
    );
}


export default HighChartHome;




