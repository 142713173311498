import React, { Component } from 'react';
import niftylogo from '../../public/images/nifty-50.png';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import "../../public/css/home.scss";


class Ticker extends Component {
    constructor() {
        super();
        this.state = {
            nifty: null,
            statusApi: `/api/market-status`,
            marketStatus:'',
            statusColor:'',
            currentDate: "",
            nextTradingDate: ""
        }
    };

    componentDidMount() {
        try {
            this.loadMarketRate();
            this.loadMarketStatus(this.state.statusApi);
            // setInterval(this.loadMarketRate, 30000);
        } catch (e) {
            console.log(e);
        }
    }
    loadMarketStatus(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({marketStatus:data.marketstatus,statusColor:data.statusColor, currentDate: data.currentDate, nextTradingDate:data.nxtTradeDate})
            })
            .catch((e) => { console.log("error in loading market status", e) })
    }
    loadMarketRate() {
        fetch(`/api/nifty-market-rate`)
            .then(resp => resp.json())
            .then((data) => {
                if (data[0]) {
                    this.setState({ nifty: data[0] });
                }
            })
            .catch((e) => { console.log("error in loading ticker", e) })
    }

    render() {

        return <>
        
        <div class="member-container">
        {/* <h1>Pure Css Button Hover effect</h1> */}
        <a href="/membership/membership-forms" class="membership-button flash-buttons">Become A Member</a>
        </div>
        </>
    }
        
}

export default Ticker;

